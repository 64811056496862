import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

export const SearchContext = createContext();

export function SearchContextProvider({ children }) {
  const [promotions, setPromotions] = useState([]);
  const [searchResults, setSearchResults] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (promotions.length && query.length) {
      const results = [];
      promotions.forEach((promotion) => {
        if (promotion.type === 'schedule' && promotion.products.length) {
          const filtredProducts = promotion.products.filter(
            (product) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              product.title.toLowerCase().split(query.toLowerCase()).length -
                1 >
              0,
          );

          if (filtredProducts.length) {
            results.push({
              ...promotion,
              products: filtredProducts,
            });
          }
        }
      });
      setSearchResults(results);
    }
  }, [promotions, query]);

  return (
    <SearchContext.Provider
      value={{
        searchResults,
        setSearchResults,
        query,
        setQuery,
        promotions,
        setPromotions,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

SearchContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
