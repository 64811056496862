/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                Badge Component                               */
/* -------------------------------------------------------------------------- */

function Badge({ category }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation('Common');

  const [badgeStyle, setBadgeStyle] = useState({});

  /* ******************************** CALLBACKS ******************************* */

  // Since badge background color
  // is retrieved from the backend
  // the use of an line style in this
  // case is a must
  const injectReactiveBadgeStyle = useCallback(() => {
    if (!category.color.includes('#')) {
      const [, colorCode] = category.color?.split('-');
      setBadgeStyle({
        backgroundColor: `#${colorCode}`,
      });
    } else {
      setBadgeStyle({
        backgroundColor: category.color,
      });
    }
  }, [category.color]);

  /* ********************************** HOOKS ********************************* */

  // -- Side Effects
  useEffect(() => {
    if (category.color) {
      injectReactiveBadgeStyle();
    }
  }, [category.color, injectReactiveBadgeStyle]);

  /* ******************************** RENDERING ******************************* */
  return (
    <div className="badge" style={badgeStyle}>
      <span>{t(`categories.${category.key}`)}</span>
    </div>
  );
}

Badge.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
};

export default Badge;
