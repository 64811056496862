// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Style
import './index.scss';

function Day({ date, onSelect, selected, disabled }) {
  return (
    <button
      type="button"
      className={`day ${selected ? 'selected' : ''}
       ${disabled ? 'disabled-day' : ''}`}
      onClick={() => {
        onSelect(date);
      }}
    >
      <p className="day-of-week">
        {format(new Date(date), 'E', {
          locale: fr,
        }).toUpperCase()}
      </p>
      <p className="date">{new Date(date).getDate()}</p>
    </button>
  );
}

Day.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Day;
