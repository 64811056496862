/* eslint-disable implicit-arrow-linebreak */
// Packages
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Container } from 'react-grid-system';
import PropTypes from 'prop-types';
import {
  format,
  eachDayOfInterval,
  isSunday,
  previousSunday,
  nextSunday,
  nextSaturday,
  isSameDay,
} from 'date-fns';
import { navigate } from 'gatsby';
// Local UI components
import Day from '../Day';

// Context API
import { DateContext } from '../../../context/DateContext';

// Style
import './index.scss';

function Calendar({ insideDrawer, setSideDrawerOpen }) {
  const getDaysOfWeekForDate = (date) =>
    eachDayOfInterval({
      start: isSunday(new Date(date))
        ? new Date()
        : previousSunday(new Date(date)),
      end: nextSaturday(new Date(date)),
    }).map((d) => format(new Date(d), 'yyyy-MM-dd'));

  // State
  const context = useContext(DateContext);

  const [weeks, setWeeks] = useState([]);

  // Callbacks
  const makeWeeks = useCallback(() => {
    const today = new Date();
    const firstWeek = getDaysOfWeekForDate(today);
    const secondWeek = isSunday(today)
      ? getDaysOfWeekForDate(today)
      : getDaysOfWeekForDate(new Date(nextSunday(today)));
    setWeeks((otherWeeks) => [...otherWeeks, firstWeek, secondWeek]);
  }, []);

  const onDateSelect = (date) => {
    context.selectDate(date);
    navigate('/', { state: { date } });
  };

  // Side-effects
  useEffect(() => {
    makeWeeks();
    return () => setWeeks([]);
  }, [makeWeeks]);

  return (
    <div className={insideDrawer ? 'drawer-calendar' : 'calendar'}>
      <Container className="fluid" fluid>
        {weeks.map(
          (week, index) =>
            index < 1 && (
              <div className="week" key={week}>
                {week.map(
                  (date, dayIndex) =>
                    dayIndex <= 6 && (
                      <Day
                        insideDrawer
                        key={date}
                        date={date}
                        onSelect={onDateSelect}
                        selected={isSameDay(
                          new Date(date),
                          new Date(context.selectedDate),
                        )}
                        setSideDrawerOpen={setSideDrawerOpen}
                      />
                    ),
                )}
              </div>
            ),
        )}
      </Container>
    </div>
  );
}

Calendar.propTypes = {
  insideDrawer: PropTypes.bool,
  setSideDrawerOpen: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  insideDrawer: false,
};

export default Calendar;
