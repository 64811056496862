/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { Container } from 'react-grid-system';

// Style
import './index.scss';
import Badge from '../Badge';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const Categories = () => {
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="categories flex items-center fluid">
      <Badge category="theatre" />
      <Badge category="exhibition" />
      <Badge category="cinema" />
      <Badge category="music" />
    </Container>
  );
};

export default Categories;
