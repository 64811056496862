/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link, navigate } from 'gatsby';

// Ui lib components
import { Container } from 'react-grid-system';

// Local UI components
import Categories from '../../UIKit/Categories';
import Image from '../../Image';

// Local images

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Page Footer Component                        */
/* -------------------------------------------------------------------------- */

function PageFooter() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation('Common');

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="page-footer fluid " fluid>
      <Container className="main-layout fluid flex justify-between">
        <Container className="left-side fluid">
          <Container className="logo fluid" onClick={() => navigate('/')}>
            <Image src="logo.png" loading="lazy" alt="logo" />
          </Container>
          <Categories />
          <Container className="links fluid">
            <Link to="/contact">{t('layout.footer.contact')}</Link>
            <Link to="/contact">{t('layout.footer.who_r_we')}</Link>
            <Link to="/contact">{t('layout.footer.recruitment')}</Link>
            <Link to="/contact">{t('layout.footer.advertising')}</Link>
            <Link to="/privacy">{t('layout.footer.personal_data')}</Link>
            <Link to="/contact">{t('layout.footer.newspaper')}</Link>
          </Container>
          <Container className="social-media fluid">
            <Image src="facebook.png" loading="lazy" alt="facebook" />
            <Image src="instagram.png" loading="lazy" alt="instagram" />
          </Container>
        </Container>
        <Container className="right-side mr-0">
          <p className="title">{t('layout.footer.download')}</p>
          <Container className="download-icons fluid">
            <Image src="googlePlay.png" loading="lazy" alt="google" />
            <a
              href="https://apps.apple.com/us/app/onyva-events/id1666637349"
              target="_blank"
              rel="noreferrer"
            >
              <Image src="appStore.png" loading="lazy" alt="apple" />
            </a>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default PageFooter;
