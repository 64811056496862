/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                              Page Layout Component                         */
/* -------------------------------------------------------------------------- */

function Backdrop({ click }) {
  /* ******************************** RENDERING ******************************* */
  return <div className="backdrop" onClick={click} role="presentation" />;
}

Backdrop.propTypes = {
  click: PropTypes.func.isRequired,
};

export default Backdrop;
