/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
// UI lib component
import { Container, Hidden } from 'react-grid-system';

// Local UI components
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import SideDrawer from './PageHeader/SideDrawer/SideDrawer';

// Context
import { DateContextProvider } from '../../context/DateContext';

// Style
import './index.scss';
import BackDrop from './PageHeader/Backdrop/Backdrop';

/* -------------------------------------------------------------------------- */
/*                              Page Layout Component                         */
/* -------------------------------------------------------------------------- */

function PageLayout({ children }) {
  const location = useLocation();
  const preSelectDate = location?.state?.date;
  /* ********************************** HOOKS ********************************* */
  useEffect(() => {
    document.body.addEventListener('scroll', {
      passive: true,
    });
    return () => {
      document.body.removeEventListener('scroll', {
        passive: true,
      });
    };
  }, []);
  // Responsive Side Drawer State
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => setSideDrawerOpen(!sideDrawerOpen);

  const backdropClickHandler = () => setSideDrawerOpen(false);

  /* ******************************** RENDERING ******************************* */

  return (
    <DateContextProvider preSelectedDate={preSelectDate}>
      <Container className="page-layout fluid scroll" fluid>
        <PageHeader
          drawerIsOpen={sideDrawerOpen}
          drawerClickHandler={drawerToggleClickHandler}
        />

        <Hidden lg xl xxl>
          {sideDrawerOpen && (
            <>
              <SideDrawer setSideDrawerOpen={setSideDrawerOpen} />
              <BackDrop click={backdropClickHandler} />
            </>
          )}
        </Hidden>
        <main>{children}</main>
        <PageFooter />
      </Container>
    </DateContextProvider>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
