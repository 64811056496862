/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useContext } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

import { Container, Hidden } from 'react-grid-system';

// Context
import { SearchContext } from '../../../context/SearchContext';

// Entities
import { allCategories } from '../../Helpers/entities';

// Local UI components
import Badge from '../../UIKit/Badge';
import Calendar from '../../UIKit/Calendar';
import SearchInput from '../../UIKit/Search';

// Local images
import LOGO from '../../../images/logo.svg';
import MENU_ICON from '../../../images/icons/menu.png';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Page Header Component                        */
/* -------------------------------------------------------------------------- */

function PageHeader({ drawerClickHandler, drawerIsOpen }) {
  /* ******************************** CONSTANTS ******************************* */

  const CATEGORIES = allCategories();
  /* ********************************** HOOKS ********************************* */

  // Context
  const context = useContext(SearchContext);

  const onSearch = (event) => {
    context?.setQuery(event.target.value);
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <header className="page-header">
      <div className="content flex">
        <Link className="logo" to="/">
          <img src={LOGO} loading="lazy" alt="logo" />
        </Link>
        <Hidden lg xl xxl>
          {!drawerIsOpen && (
            <Container className="mr-0 fluid" onClick={drawerClickHandler}>
              <img className="hamburger" src={MENU_ICON} alt="" />
            </Container>
          )}
        </Hidden>
        <Hidden sm xs md>
          <Container className="categories flex items-center fluid" fluid>
            {CATEGORIES.map((c) => (
              <Badge category={c} />
            ))}
          </Container>
        </Hidden>
      </div>

      <div className="calendar-container">
        <Calendar />
      </div>
      <div className="search-container-layout">
        <Hidden sm xs md>
          <SearchInput onSearch={onSearch} />
        </Hidden>
      </div>
    </header>
  );
}

PageHeader.propTypes = {
  drawerClickHandler: PropTypes.func.isRequired,
  drawerIsOpen: PropTypes.bool.isRequired,
};

export default PageHeader;
