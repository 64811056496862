/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-grid-system';

// Local UI components
import SEARCH_ICON from '../../../images/icons/search_frame.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const SearchInput = ({ onSearch }) => {
  /* ********************************** HOOKS ********************************* */

  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="search-container fluid" fluid>
      <input onChange={onSearch} placeholder={t('search')} />
      <img className="search-icon" src={SEARCH_ICON} alt="search" />
    </Container>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchInput;
