/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useContext } from 'react';
import { Link } from '@reach/router';
import { Container, Row } from 'react-grid-system';
import PropTypes from 'prop-types';

// Context
import { SearchContext } from '../../../../context/SearchContext';

// UI lib component
import Calendar from '../../../UIKit/Calendar';
import SearchInput from '../../../UIKit/Search';
import Categories from '../../../UIKit/Categories';

// Local UI components
import LOGO from '../../../../images/logo.svg';
import CLOSE_ICON from '../../../../images/icons/close.png';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                              Page Layout Component                         */
/* -------------------------------------------------------------------------- */

function SideDrawer({ setSideDrawerOpen }) {
  /* ********************************** HOOKS ********************************* */
  // Context
  const context = useContext(SearchContext);

  const onSearch = (event) => {
    context?.setQuery(event.target.value);
  };
  /* ******************************** RENDERING ******************************* */

  return (
    <nav className="side-drawer">
      <Container>
        <button
          type="button"
          className="close-modal"
          onClick={() => setSideDrawerOpen(false)}
        >
          <img
            className="close-icon"
            style={{ width: '10px' }}
            src={CLOSE_ICON}
            loading="lazy"
            alt="close"
          />
        </button>
        <Row className="header-row" justify="around">
          <Link className="logo" to="/">
            <img src={LOGO} loading="lazy" alt="logo" />
          </Link>
          <div className="search-container-layout">
            <SearchInput onSearch={onSearch} />
          </div>
        </Row>

        <Row className="mb-20" align="center" justify="center">
          <Categories />
        </Row>

        <Row>
          <Calendar isDrawer setSideDrawerOpen={setSideDrawerOpen} />
        </Row>
      </Container>
    </nav>
  );
}
SideDrawer.propTypes = {
  setSideDrawerOpen: PropTypes.func.isRequired,
};

export default SideDrawer;
