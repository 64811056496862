/* ---------------------------------------------------------------------------- */
/*  A helper providing information about categories and product categorisation  */
/* ---------------------------------------------------------------------------- */

/**
 * An iteratable array listing all categories and mapping them
 * to server collection entries (name)
 */
const allCategories = () => [
  {
    name: 'cinema',
    key: 'cinema',
    title: 'Cinéma',
    color: '#d5ff00',
  },
  {
    name: 'theatre',
    key: 'theatre',
    title: 'Théatre',
    color: '#ff0044',
  },
  {
    name: 'music',
    key: 'music',
    title: 'Musique',
    color: '#00ff77',
  },
  {
    name: 'exhibition',
    key: 'exhibition',
    title: 'Exposition',
    color: '#00f6ff',
  },
];

/**
 * A handy object collection for pickin a given category by name
 */
const allCategoriesByName = () => ({
  cinema: {
    title: 'Cinéma',
    color: '#EBFF00',
  },
  theatre: {
    title: 'Théatre',
    color: '#FF0055',
  },
  music: {
    title: 'Musique',
    color: '#00FF78',
  },
  exhibition: {
    title: 'Exposition',
    color: '#00F0FF',
  },
});

/**
 * Returns the product qualifier with corresponding route
 * depending on its category
 * Ex: cinema -> { title: Film, typeName: 'Genre', route: /movies}
 * @param {String} categoryName - Name (key) of the category
 */
const productQualifierByCategory = (categoryName) => {
  const allQualifiers = {
    cinema: {
      title: 'Film',
      pluralTitle: 'Films',
      typeName: 'Genre',
      route: '/movies',
      key: 'movie',
      pluralKey: 'movies',
      preposition: 'Du',
      articlePrefix: 'Le',
    },
    theatre: {
      title: 'Pièce',
      pluralTitle: 'Pièces',
      typeName: 'Genre',
      route: '/plays',
      key: 'play',
      pluralKey: 'plays',
      preposition: 'De la',
      articlePrefix: 'La',
    },
    music: {
      title: 'Spectacle',
      pluralTitle: 'Spectacles',
      typeName: 'Style',
      route: '/gigs',
      key: 'gig',
      pluralKey: 'gigs',
      preposition: 'Du',
      articlePrefix: 'Le',
    },
    exhibition: {
      title: 'Exposition',
      pluralTitle: 'Expositions',
      typeName: 'Thème',
      route: '/exhibitions',
      key: 'exhibition',
      pluralKey: 'exhibitions',
      preposition: "De l'",
      articlePrefix: "L'",
    },
  };
  return allQualifiers[categoryName];
};

/**
 * Returns the venue qualifier depending on its category
 * Ex: cinema -> Salles (T)
 * @param {String} categoryName - Name (key) of the category
 */
const venueQualifierByCategory = (categoryName) => {
  const allQualifiers = {
    cinema: {
      title: 'Salle',
      pluralTitle: 'Salles',
    },
    theatre: {
      title: 'Théâtre',
      pluralTitle: 'Théâtres',
    },
    music: {
      title: 'Espace',
      pluralTitle: 'Espaces',
    },
    exhibition: {
      title: 'Gallerie',
      pluralTitle: 'Galleries',
    },
  };
  return allQualifiers[categoryName];
};

export {
  allCategories,
  allCategoriesByName,
  productQualifierByCategory,
  venueQualifierByCategory,
};
